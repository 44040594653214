import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const DeleteModal = ({
    isOpen,
    onClose,
    onConfirm,
    itemType,
    itemDescription = ''
}) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure you want to delete this {itemType}
                    {itemDescription && ` ${itemDescription}`}?
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button
                        variant="customRoundedTextDanger"
                        color="error"
                        onClick={onConfirm}
                        style={{ marginRight: '1rem' }}
                    >
                        Yes, delete
                    </Button>
                    <Button
                        variant="customRoundedText"
                        color="secondary"
                        onClick={onClose}
                    >
                        No
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteModal;