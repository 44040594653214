import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    setLoading,
    setError,
} from '../../Api/slice';
import {
    jobProxy,
    aiClassifierProxy,
} from '../../../../Common/CoreComponents/AjaxProxy';
import _ from 'lodash';
import {
    setIdentifiedDocuments,
    setJobs,
    setSelectedJob,
    setRandomFiles,
    setSelectedJobFile,
    setDocuments,
    setJobPreview
} from './JobsSlice';
import { getJobs, getSelectedJob, getJobPreview } from './selectors';

const apiTypes = {
    fetchJobs: 'fetchJobs',
    startJob: 'startJob',
    resetJob: 'resetJob',
    stopJob: 'stopJob',
    cancelJob: 'cancelJob',
    fetchJobFiles: 'fetchJobFiles',
    fetchJobFilePreview: 'fetchJobFilePreview',
    fetchDocuments: 'fetchDocuments',
    createJob: 'createJob',
    getJobZipResult: 'getJobZipResult',
    calculateJobPreview: 'calculateJobPreview',
    deleteJob: 'deleteJob',
    fetchDocumentTypesIds: 'fetchDocumentTypesIds'
}

export const fetchJobs = createAsyncThunk(apiTypes.fetchJobs,
    async (_, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await jobProxy.getJobs();
            dispatch(setJobs(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

export const startJob = createAsyncThunk(apiTypes.startJob,
    async (jobId, { dispatch, getState }) => {
        dispatch(setLoading(true));
        try {
            const response = await aiClassifierProxy.startJob(jobId);
            // const state = getState();
            // const jobs = getJobs(state);
            // const jobsCopy = _.cloneDeep(jobs || []);
            // const idx = jobsCopy.findIndex(x => x.Id === jobId)
            // if (idx !== -1) {
            //     jobsCopy.splice(idx, 1, response);
            // }
            // dispatch(setJobs(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });
    export const resetJob = createAsyncThunk(apiTypes.resetJob,
        async (jobId, { dispatch, getState }) => {
            dispatch(setLoading(true));
            try {
                const response = await aiClassifierProxy.resetJob(jobId);
                // const state = getState();
                // const jobs = getJobs(state);
                // const jobsCopy = _.cloneDeep(jobs || []);
                // const idx = jobsCopy.findIndex(x => x.Id === jobId)
                // if (idx !== -1) {
                //     jobsCopy.splice(idx, 1, response);
                // }
                // dispatch(setJobs(response));
            } catch (exception) {
                dispatch(setError(exception.message));
            } finally {
                dispatch(setLoading(false));
            }
        });
export const stopJob = createAsyncThunk(apiTypes.stopJob,
    async (jobId, { dispatch, getState }) => {
        dispatch(setLoading(true));
        try {
            const response = await jobProxy.stopJob(jobId);
            const state = getState();
            const jobs = getJobs(state);
            const jobsCopy = _.cloneDeep(jobs || []);
            const idx = jobsCopy.findIndex(x => x.Id === jobId)
            if (idx !== -1) {
                jobsCopy.splice(idx, 1, response);
            }
            dispatch(setJobs(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });
export const cancelJob = createAsyncThunk(apiTypes.cancelJob,
    async (jobId, { dispatch, getState }) => {
        dispatch(setLoading(true));
        try {
            const response = await jobProxy.cancelJob(jobId);
            const state = getState();
            const jobs = getJobs(state);
            const jobsCopy = _.cloneDeep(jobs || []);
            const idx = jobsCopy.findIndex(x => x.Id === jobId)
            if (idx !== -1) {
                jobsCopy.splice(idx, 1, response);
            }
            dispatch(setJobs(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

export const fetchJobFiles = createAsyncThunk(apiTypes.fetchJobFiles,
    async (jobId, { dispatch, getState }) => {
        dispatch(setLoading(true));
        try {
            const state = getState();
            const selectedJob = getSelectedJob(state);
            const response = await jobProxy.getJobFiles(jobId);
            const obj = { ...selectedJob, 
                Id: jobId, 
                Files: response, 
                ProcessorFk: response[0].ProcessorFk, 
                Status: response[0].Status };
            dispatch(setSelectedJob(obj));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

export const fetchJobFilePreview = createAsyncThunk(apiTypes.fetchJobFilePreview,
    async (aiFileId, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await jobProxy.getFilePreview(aiFileId);
            const obj = { Id: aiFileId, ScannedPages: response };
            dispatch(setSelectedJobFile(obj));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

export const updateJobFilePage = createAsyncThunk(apiTypes.updateJobFilePage,
    async ({ pageId, patchArray }, { dispatch, getState }) => {
        dispatch(setLoading(true));
        try {
            const selectedJobFile = getState().jobs.selectedJobFile;
            const response = await jobProxy.updateJobFilePage(pageId, patchArray);
            dispatch(setSelectedJobFile({
                ...selectedJobFile,
                ScannedPages: selectedJobFile.ScannedPages.map(page => {
                  if (page.Id == pageId) {
                    return {
                      ...page,
                      Validated: response.Validated,
                      ValidatedDocumentTypeFk: response.ValidatedDocumentTypeFk,
                      ValidatorFk: response.ValidatorFk
                    };
                  }
                  return page;
                })
              }));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);
export const fetchDocuments = createAsyncThunk(apiTypes.fetchDocuments,
    async ({ classifierId, filter }, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await jobProxy.getDocuments(classifierId, filter);
            dispatch(setDocuments(response));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

export const calculateJobPreview = createAsyncThunk(
    apiTypes.calculateJobPreview,
    async (jobModel, { dispatch, getState }) => {
        dispatch(setLoading(true));
        try {
            const jobPreview = await jobProxy.calculateJobPreview(jobModel);
            dispatch(setJobPreview(jobPreview));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });
export const createJob = createAsyncThunk(apiTypes.createJob,
    async (job, { dispatch, getState }) => {
        const state = getState();
        const jobs = getJobs(state);
        const jobsCopy = _.cloneDeep(jobs || []);
        dispatch(setLoading(true));
        try {
            const response = await jobProxy.createJob(job);
            jobsCopy.push(response);
            dispatch(setJobs(jobsCopy));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

export const deleteJob = createAsyncThunk(apiTypes.deleteJob,
    async (jobId, { dispatch, getState }) => {
        const state = getState();
        const jobs = getJobs(state);
        const jobsCopy = _.cloneDeep(jobs || []);
        dispatch(setLoading(true));
        try {
            const response = await jobProxy.deleteJob(jobId);
            const index = jobsCopy.findIndex(job => job.Id === response);
            if (index !== -1) {
                jobsCopy.splice(index, 1);
            }
            dispatch(setJobs(jobsCopy));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const fetchDocumentTypesIds = createAsyncThunk(apiTypes.fetchDocumentTypesIds,
    async ( classifierId, { dispatch, getState }) => {
        dispatch(setLoading(true));
        try {
            const state = getState();
            const selectedJob = getSelectedJob(state);
            const response = await jobProxy.getJobDocumentTypesIds(classifierId);
            const obj = { ...selectedJob, DocumentTypesIds: response };
          //  dispatch(setSelectedJob(obj));
        } catch (exception) {
            dispatch(setError(exception.message));
        } finally {
            dispatch(setLoading(false));
        }
    });

    export const getJobZipResult = createAsyncThunk(apiTypes.getJobZipResult,
        async (jobId, { dispatch }) => {
            dispatch(setLoading(true));
            try {
                await jobProxy.getJobZipResult(jobId);
            } catch (exception) {
                dispatch(setError(exception.message));
            } finally {
                dispatch(setLoading(false));
            }
        }
    );
