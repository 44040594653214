import React from 'react';
import * as Thunks from '../../Pages/Documents/Api/thunk';
import { connect } from 'react-redux';
import { Box, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

function Logs({
    getLogsAiApp,
    getLogsClassificationApp,
    getLogsSearchablePdfBuilderApp
}) {
    return (
        <Box display="flex" justifyContent="flex-start">
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} width="auto">
                <Button
                    onClick={getLogsAiApp}
                    variant="customRounded"
                    startIcon={<DownloadIcon />}
                >
                    AI App logs
                </Button>
                <Button
                    onClick={getLogsClassificationApp}
                    variant="customRounded"
                    startIcon={<DownloadIcon />}
                >
                    Classification App logs
                </Button>
                <Button
                    onClick={getLogsSearchablePdfBuilderApp}
                    variant="customRounded"
                    startIcon={<DownloadIcon />}
                >
                    Searchable PDF Builder logs
                </Button>
            </Box>
        </Box>
    );
}

const mapDispatchToProps = {
    getLogsAiApp: Thunks.getLogsAiApp,
    getLogsClassificationApp: Thunks.getLogsClassificationApp,
    getLogsSearchablePdfBuilderApp: Thunks.getLogsSearchablePdfBuilderApp,
}

export default connect(null, mapDispatchToProps)(Logs);
