import React, { useEffect, useState } from 'react';
import * as Selectors from '../../Api/selectors';
import * as Thunks from '../../Api/thunk';
import * as SliceActions from '../../Api/slice';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../../Shared/Helpers';
import { connect } from 'react-redux';
import {
    Box, Button, CircularProgress,
    Divider, FormControl, MenuItem, Select,
    TextField, Tooltip, Typography, Checkbox
} from '@mui/material';
import _ from 'lodash';
import TableComponent from '../../../../Shared/Components/TableComponent';
import DocumentImageList from '../../../../Widgets/DocumentSelection/Ui/ImageList';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { documentHubProxy } from '../../../../Common/CoreComponents/SignalRProxy';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from 'Shared/Components/DeleteModal';

const rowsPerPageArray = [10, 15, 20, 50, 100];

const tableColumns = [
    {
        align: 'left',
        name: 'File Name',
        value: 'FileName',
        columnMaxWidth: '16rem',
        isTitleShown: true,
    },
    {
        align: 'left',
        name: 'Identified',
        value: 'Identified',
    },
    {
        align: 'left',
        name: 'Description',
        value: 'Description',
        columnMaxWidth: '12rem',
        isTitleShown: true,
    },
    {
        align: 'left',
        name: 'Actions',
        value: 'Actions',
    }
];

function FileSelection({
    filteredFiles,
    fetchFiles,
    fetchFilePreview,
    setIsFilePages,
    fileFilter,
    changeSelectedPages,
    setSelectedFile,
    selectedFile,
    sharedCurrentPages,
    sharedIdentifyPages,
    setSelectedFileFilter,
    selectedPages,
    setSelectedPages,
    setSelectedDocumentType,
    setSelectedFileDocumentType,
    dataSamples,
    identifyDocumentPage,
    identifyDocument,
    setDocumentAsIdentified,
    currentSample,
    setCurrentSample,
}) {

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [loadingFilePreview, setLoadingFilePreview] = useState(false);
    const [includeIdentifiedPage, setIncludeIdentifiedPages] = useState(false);
    const [deletedFile, showDeleteModal] = useState(null);

    const navigate = useNavigate();
    const query = useQuery();

    useEffect(() => {
        setCurrentSample({});
        setSelectedPages([]);
        const fileId = query.get('FileId');
        const fileName = query.get('FileName');
        const itemsCount = query.get('ItemsPerPage');
        const isIdentified = query.get('IsIdentified');
        const fileDescription = query.get('Description');
        const createdFrom = query.get('CreatedFrom');
        const createdTo = query.get('CreatedTo');
        const page = query.get('PageNumber');

        const filter = {
            Initialized: true,
            ...(fileName ? { FileName: fileName } : {}),
            ...(fileDescription ? { Description: fileDescription } : {}),
            ...(createdFrom ? { CreatedFrom: createdFrom } : {}),
            ...(createdTo ? { CreatedTo: createdTo } : {}),
            ...(itemsCount ? { ItemsPerPage: parseInt(itemsCount, 10) } : {}),
            ...(page ? { PageNumber: parseInt(page, 10) } : {}),
            ...(!_.isNil(isIdentified) ? { IsIdentified: isIdentified } : {}),
        };
        setSelectedFileFilter(filter);
        setIsFilePages(true);
        if (!fileId) return;
        setLoadingFilePreview(true);
        fetchFilePreview({ documentFK: fileId, isFile: true }).then(() => {
            setLoadingFilePreview(false);
        });
    }, [])

    useEffect(() => {
        if (!fileFilter.Initialized) return;
        setLoadingFiles(true);
        fetchFiles(fileFilter).then(() => {
            setLoadingFiles(false);
        });
    }, [fetchFiles, fileFilter]);

    useEffect(() => {
        let resultUri = selectedFile.DocumentFK ? `?FileId=${selectedFile.DocumentFK}&` : `?`;
        let needPrefix = false;
        for (const [key, value] of Object.entries(fileFilter)) {
            if (fileFilter[key] !== null && fileFilter[key] !== '') {
                if (needPrefix) resultUri += '&';
                resultUri += `${key}=${value}`;
                needPrefix = true;
            }
        }
        navigate(resultUri);
    }, [fileFilter, selectedFile]);

    const openDeleteModal = (deletedFile) => {
        showDeleteModal(deletedFile);
    }
    const closeDeleteModal = () => {
        showDeleteModal(null);
    }
    const handleDeleteButtonClick = (deletedFile) => {
        if (!deletedFile) return;
        identifyDocument(deletedFile);
        setSelectedFile(null);
        showDeleteModal(null);
    };
    const getRows = () => {
        return filteredFiles.map((value) => {
          if (!fileFilter.IsIdentified && value.IdentifiedPages === value.PagesCount) {
            return null;
          }

          const identified = value.IdentifiedPages !== value.PagesCount
                ? `${value.IdentifiedPages}/${value.PagesCount}`
                : <DoneIcon sx={{ color: 'primary.main' }} />;

          const deleteButton = value.IdentifiedPages !== value.PagesCount
            ? <Button
              variant="customRoundedTextDanger"
              sx={{ml: 0}}
              size="small"
              onClick={() => {
                openDeleteModal(value.Id)
              }}
            >
              <DeleteIcon />
            </Button>
            : null;

          return {
            Id: value.Id,
            FileName: value.FileName,
            Description: value.Description,
            Identified: identified,
            Created: value.Created,
            PagesCount: value.PagesCount,
            Actions: deleteButton
          }
        }).filter(row => row !== null);
      };

    const handleChangePage = (event, newPage) => {
        const newValidatePage = newPage < 1 ? 1 : newPage;
        setSelectedFileFilter({ PageNumber: newValidatePage });
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setSelectedFileFilter({ ItemsPerPage: newRowsPerPage, PageNumber: 1 });
    }
    const handleRowItemClick = (value) => {
        const wasIdentifiedPageInFile = dataSamples?.DocumentPages.some(page => 
            page.ScannedPageId === selectedFile?.ScannedPages?.ScannedPageId);

        if (wasIdentifiedPageInFile || selectedFile.DocumentFK !== value.Id) {
            setSelectedPages([]);
            setLoadingFilePreview(true);
            fetchFilePreview({ documentFK: value?.Id ? value?.Id : selectedFile?.DocumentFK, isFile: true }).then(() => {
                setLoadingFilePreview(false);
            });
        }
    }

    const handleSelectedRow = (row) => {
        return row.Id === selectedFile.DocumentFK || selectedPages.some(x => x.DocumentFK === row.Id);
    }

    const isImageIdentifyOrSelectedByOther = (image) => {
        return image.IsIdentified 
        || (sharedCurrentPages || []).find(x => x === image.ScannedPageId)
        || (sharedIdentifyPages || []).find(x => x === image.ScannedPageId)
    }

    const handleImageSelection = async(value, selectAll = false) => {
        if (selectAll) {
            setSelectedPages([selectedFile]);
                    documentHubProxy.updateCurrentPages(
                        selectedFile.ScannedPages
                            .filter(image => !isImageIdentifyOrSelectedByOther(image))
                            .map(x => x.ScannedPageId), true);
                    return;
        }
        changeSelectedPages(value);
        let isSelected = true;
        if (selectedPages.length !== 0)
            isSelected = selectedPages[0].ScannedPages
                .findIndex(x => x.ScannedPageId === value.ScannedPageId) === -1;
        documentHubProxy.updateCurrentPages([value.ScannedPageId], isSelected);
    }

    const handleContinueButton = () => {
        setCurrentSample({
            Id: 'new',
            DocumentPages: selectedPages,
            LabeledAreas: selectedPages[0]
                .ScannedPages
                .reduce((acc, { ScannedPageId }) => {
                    acc[ScannedPageId] = [];
                    return acc;
                }, {})
        });
        if (selectedPages.length <= 0) {
            setSelectedPages([selectedFile]);
        }
        setIsFilePages(true);
        setSelectedDocumentType(null);
        setSelectedFileDocumentType(null);
        setSelectedPages([]);
        navigate(_.isNil(selectedFile.DocumentFK) ? '#' : '/documents/preview/new');
    }
    

    const handleUnselect = () => {
        setSelectedPages([]);
        setSelectedFile(null);
    }

    const isImageSelected = (value) => {
        const scannedPages = _.get(selectedPages, '[0].ScannedPages');
        if (!scannedPages) return false;
        return scannedPages.some(x => x.ScannedPageId === value.ScannedPageId);
    }

    const handleIdentificationDocumentsChange = (event) => {
        setSelectedFileFilter({ IsIdentified: event.target.checked || false });
        setSelectedFile(null)
    };

    const handleIdentificationPagesChange = (event) => {
        setIncludeIdentifiedPages(event.target.checked);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            minHeight: '87dvh'
        }}>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{
                            gap: '2em', width: '100%', mr: '2em',
                            display: 'flex', justifyContent: 'space-between'
                        }}>
                            <TextField
                                size="small"
                                sx={{ width: '100%' }}
                                variant="outlined"
                                label="File Name"
                                value={_.get(fileFilter, 'FileName', '') || ''}
                                onChange={(e) => setSelectedFileFilter({ FileName: e.target.value })}
                            />
                            <TextField
                                size="small"
                                sx={{ width: '100%' }}
                                variant="outlined"
                                label="File Description"
                                value={_.get(fileFilter, 'Description', '') || ''}
                                onChange={(e) => setSelectedFileFilter({ Description: e.target.value })}
                            />
                        </Box>
                        <Box sx={{ marginLeft: 'auto' }}>
                            <Button
                                variant="customRounded"
                                size="medium"
                                onClick={handleUnselect}
                                startIcon={<CloseOutlinedIcon />}
                                disabled={selectedPages.length === 0}
                            >Unselect</Button>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex', width: '50%', flexDirection: 'row',
                        gap: '10px', paddingLeft: '10em', justifyContent: 'flex-end'
                    }}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row', width: '70%',
                            justifyContent: 'space-between'
                        }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker sx={{ maxWidth: '15rem' }}
                                    key="from-date-picker"
                                    label="Created From"
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={dayjs(_.get(fileFilter, 'CreatedFrom', null))}
                                    onChange={(date) => setSelectedFileFilter({ CreatedFrom: _.isNil(date) ? null : dayjs(date, 'YYYY-MM-DD') })}
                                />
                                <DatePicker
                                    sx={{ marginLeft: '0.5rem', maxWidth: '15rem' }}
                                    key="to-date-picker"
                                    label="Created To"
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={fileFilter && fileFilter.CreatedTo ? dayjs(fileFilter.CreatedTo, 'YYYY-MM-DD') : null}
                                    onChange={(date) => setSelectedFileFilter({ CreatedTo: _.isNil(date) ? null : dayjs(date, 'YYYY-MM-DD') })}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', marginTop: '0.5rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography>Files:</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}><Typography>Include Identified</Typography>
                                <Tooltip title='Identified documents'>
                                    <Checkbox
                                        size='small'
                                        checked={fileFilter.IsIdentified}
                                        onChange={handleIdentificationDocumentsChange}
                                    />
                                </Tooltip></Box>
                        </Box>
                        {deletedFile
                            ? <DeleteModal
                                isOpen={deletedFile !== null}
                                onClose={closeDeleteModal}
                                onConfirm={() => handleDeleteButtonClick(deletedFile)}
                                itemType="File"
                            />
                            : null}
                        {loadingFiles ? <CircularProgress /> :
                            filteredFiles.length < 1 || _.isEmpty(filteredFiles) ?
                                <Box sx={{ color: 'primary.main' }}>File list empty</Box> :
                                <TableComponent
                                    columns={tableColumns}
                                    rows={getRows()}
                                    handleRowItemClick={handleRowItemClick}
                                    handleSelectedRow={handleSelectedRow}
                                    tableContainerSx={{maxHeight: 'calc(100dvh - 15.4rem)'}}
                                />}
                    </Box>
                    <Divider sx={{ marginLeft: '1rem', marginRight: '1rem' }} orientation="vertical" flexItem />
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                        <Box>{_.isNil(selectedFile?.DocumentFK)
                            ? <Typography>Preview:</Typography>
                            : <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography>Preview selected file: {selectedFile.DocumentFK}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>Identified Pages</Typography>
                                    <Tooltip title='Include Identified pages'>
                                        <Checkbox
                                            size='small'
                                            checked={includeIdentifiedPage}
                                            onChange={handleIdentificationPagesChange}
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>}
                        </Box>
                        {loadingFilePreview ? <CircularProgress /> :
                            _.isNil(selectedFile?.DocumentFK) ?
                                <Box sx={{ color: 'primary.main' }}>Please select File</Box> :
                                <DocumentImageList
                                    images={selectedFile?.ScannedPages}
                                    sharedCurrentPages={sharedCurrentPages}
                                    sharedIdentifyPages={sharedIdentifyPages}
                                    propertyMap={'CdnUrl'}
                                    handleImageSelection={handleImageSelection}
                                    isImageSelected={isImageSelected}
                                    keyMap={'ScannedPageId'}
                                    pageNumberMap={'PageNumber'}
                                    includeIdentifiedPage={includeIdentifiedPage}
                                    identifyDocumentPage={identifyDocumentPage}
                                    documentFK={selectedFile?.DocumentFK}
                                />
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: _.isEmpty({}) ? 'flex-end' : 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <Tooltip title="Preview selected images">
                            <Box>
                                <Button
                                    variant="customRounded"
                                    size="medium"
                                    startIcon={<VisibilityOutlinedIcon />}
                                    disabled={_.isNil(selectedPages)
                                        || selectedPages.length === 0
                                        || selectedPages[0].ScannedPages.length === 0}
                                    onClick={() => {
                                        handleContinueButton(selectedFile.DocumentFK)
                                        //navigate(_.isNil(selectedFile.DocumentFK) ? '#' : '/documents/preview/new');
                                    }}
                                >Continue</Button>
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2rem' }}>
                        <Typography>Page:</Typography>
                        <TextField
                            sx={{ width: '4rem', marginLeft: '1rem' }}
                            variant="standard"
                            type="number"
                            value={fileFilter.PageNumber}
                            onChange={(e) => {
                                const newPage = parseInt(e.target.value, 10);
                                handleChangePage(e, _.isNaN(newPage) ? 0 : newPage)
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2rem' }}>
                        <Typography>Rows per page:</Typography>
                        <FormControl variant="standard" sx={{ width: '4rem', marginLeft: '1rem' }}>
                            <Select
                                value={fileFilter.ItemsPerPage || ''}
                                label="Age"
                                onChange={handleChangeRowsPerPage}
                            >
                                {rowsPerPageArray.map((value) => {
                                    return (
                                        <MenuItem key={`rows-per-page-${value}`} value={value}>{value}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const filteredFiles = Selectors.getFiles(state);
    const fileFilter = Selectors.getSelectedFileFilter(state);
    const selectedFile = Selectors.getSelectedFile(state);
    const selectedPages = Selectors.getSelectedPages(state);
    const sharedCurrentPages = Selectors.getSharedCurrentPages(state);
    const sharedIdentifyPages = Selectors.getSharedIdentifyPages(state);
    const currentSample = Selectors.getCurrentSample(state);
    return {
        sharedIdentifyPages,
        sharedCurrentPages,
        filteredFiles,
        fileFilter,
        selectedFile,
        currentSample,
        selectedPages
    }
}

const mapDispatchToProps = {
    fetchFiles: Thunks.fetchFiles,
    fetchFilePreview: Thunks.fetchDocumentPreview,
    setSelectedFileFilter: SliceActions.setSelectedFileFilter,
    setSelectedPages: SliceActions.setSelectedPages,
    changeSelectedPages: SliceActions.changeSelectedPages,
    setSelectedFile: SliceActions.setSelectedFile,
    setEntityList: SliceActions.setEntityList,
    setIsFilePages: SliceActions.setIsFilePages,
    setSelectedDocumentType: SliceActions.setSelectedDocumentType,
    setSelectedFileDocumentType: SliceActions.setSelectedFileDocumentType,
    identifyDocumentPage: Thunks.identifyDocumentPage,
    identifyDocument: Thunks.identifyDocument,
    setDocumentAsIdentified: SliceActions.setDocumentAsIdentified,
    setCurrentSample: SliceActions.setCurrentSample
}

export default connect(mapStateToProps, mapDispatchToProps)(FileSelection);
