import React, { useState, useEffect } from 'react';
import { Box, TextField, Checkbox, Tooltip } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import _ from 'lodash';
import TableComponent from '../../../../Shared/Components/TableComponent';

export function JobRunnerAutoPreview({
    jobPreview,
    editedJobPreview,
    setEditedJobPreview,
    config
}) {
    const createEditedJobPreview = () => {
        if (!jobPreview || !jobPreview.DocumentTypeAllSampleCountMap || !jobPreview.DocumentTypeSamples) {
            return [];
        }

        return Object.entries(jobPreview.DocumentTypeAllSampleCountMap).map(([id, count]) => {
            const isOtherDocType = jobPreview.DocumentTypeSamples[id]?.IsOtherDocType || false;
            const missingSamplesCount = jobPreview?.DocumentTypeSamples[id]?.MissingSamplesCount || null;
            const take = isOtherDocType ? 0 : jobPreview.DocumentTypeSampleCountMap[id] || 0;

            return {
                DocumentTypeId: parseInt(id),
                Take: take,
                isOtherDocType: isOtherDocType,
                isSelected: take !== 0 || !isOtherDocType,
                Count: count,
                MissingSamplesCount: missingSamplesCount
            };
        });
    };

    useEffect(() => {
        const initialEditedJobPreview = createEditedJobPreview();
        setEditedJobPreview(initialEditedJobPreview);
    }, [jobPreview]);

    const handleTakeSamples = (item) => {
        if(!item || !editedJobPreview) return;
        const updatedJobPreview = editedJobPreview.map(x => {
            if (x.DocumentTypeId === item.DocumentTypeId) {
                const newTake = !_.isNil(item.Take) ? item.Take : x.Take;
                return {
                    ...x,
                    Take: newTake,
                    isSelected: newTake !== 0
                };
            }
            return x;
        });
    
        setEditedJobPreview(updatedJobPreview);
    };

    const getRows = () => {
        if (_.isNil(config.Classifier) || _.isNil(editedJobPreview)) {
            return [];
        }
        return editedJobPreview.map((row) => {
            const id = row.DocumentTypeId;

            return {
                Selected: (
                    <Tooltip key={id} title='Select DocType' onClick={(e) => { e.stopPropagation(); }}>
                        <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={row.Take !== 0}
                                size='small'
                                onChange={() => {
                                    handleTakeSamples({
                                        DocumentTypeId: id,
                                        Take: row.Take === 0 
                                            ? jobPreview?.DocumentTypeSampleCountMap[id] || 0 
                                            : 0,
                                        isSelected: row.Take === 0
                                    });
                                }}
                            />
                        </Box>
                    </Tooltip>
                ),
                DocumentTypeId: row.DocumentTypeId,
                DocumentTypeName: jobPreview?.DocumentTypeNameMap[id] || '',
                Count: row.Count,
                Take: (
                    <TakeTextField
                        row={row}
                        id={id}
                        handleTakeSamples={handleTakeSamples}
                        isSelected={row.Take !== 0}
                    />
                ),
                IsOtherDocType: row.isOtherDocType ? 'true' : '',
                MissingSamplesCount: row.MissingSamplesCount,
            };
        }).sort((a, b) => {
            const aValue = a.IsOtherDocType === 'true' ? 1 : 0;
            const bValue = b.IsOtherDocType === 'true' ? 1 : 0;

            return aValue - bValue;
        });
    };

    return (
        <Box sx={{ width: '100%' }}>
            {!_.isNil(config.Classifier)
                ? <TableComponent
                    columns={columns}
                    rows={getRows()}
                    enableTablePagination={false}
                    handleSelectedRow={_.noop}
                    handleRowItemClick={_.noop}
                />
                : null}
        </Box>
    );
}
const columns = [
    {
        name: 'Selected',
        value: 'Selected',
    },
    {
        name: 'Id',
        value: 'DocumentTypeId',
    },
    {
        name: 'Name',
        value: 'DocumentTypeName',
    },
    {
        name: 'Samples Count',
        value: 'Count',
    },
    {
        name: 'Take',
        value: 'Take',
    },
    {
        name: 'Is Other DocType',
        value: 'IsOtherDocType',
    },
    {
        name: 'Samples missing',
        value: 'MissingSamplesCount',
    }
]


const TakeTextField = ({ row, id, handleTakeSamples, isSelected }) => {
    const [localValue, setLocalValue] = useState(row?.Take);

    useEffect(() => {
        setLocalValue(row?.Take);
    }, [row?.Take]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        const parsedValue = newValue === '' ? 0 : parseInt(newValue, 10);
        const validValue = Math.min(parsedValue, row?.Count || 0);

        setLocalValue(validValue);
    };

    const handleBlur = () => {
        const parsedValue = localValue === '' ? 0 : parseInt(localValue, 10);
        handleTakeSamples({
            DocumentTypeId: id,
            Take: isNaN(parsedValue) ? 0 : parsedValue
        });
    };

    return (
        <TextField
            variant="standard"
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            title={localValue}
            sx={{
                width: 'auto',
                "& input": {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 'auto',
                },
            }}
            InputProps={{
                endAdornment: (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <EditNoteIcon sx={{ color: 'primary.main' }} />
                    </span>
                ),
            }}
            type="number"
            inputProps={{
                min: 0,
                max: row?.Count,
                step: 1
            }}
        />
    );
};