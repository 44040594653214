import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    IconButton,
    TextField,
    Typography,
    Tabs,
    Tab
} from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _ from 'lodash';

import { FILE_FILTER_DEFAULT_STATE, DOCUMENT_FILTER_DEFAULT_STATE } from '../../Api/slice';
import { debounceFunction } from '../DataSamples/DocumentTypeSamples';
import DocumentImageList from '../../../../Widgets/DocumentSelection/Ui/ImageList';
import ClearIcon from '@mui/icons-material/Clear';
import * as Colors from '../../../../Shared/Styles/Colors';

const DEFAULT_RANDOM_FILES_COUNT = 10;
const DEFAULT_DOCUMENTS_COUNT = 5;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: '12px' }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function aplyTabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function JobRunnerManual({
    fetchClassifiers,
    classifiers,
    identifiedDocuments,
    randomFiles,
    fetchDocumentPreview,
    selectedFile,
    selectedDocument,
    jobExcludedDocs,

    fetchFiles,
    fetchDocuments,
    setShowCreate,
    createJob,
    setJobExcludedDocs,
    documentTypes,
    setDocuments,
}) {
    const [config, setConfig] = useState({
        CreatedFrom: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        CreatedTo: null,
        Classifier: null,
        Description: '',
        MaxPagesPerFile: 10,
        RandomFiles: DEFAULT_RANDOM_FILES_COUNT,
        IdentifiedDocumentsPerDocType: DEFAULT_DOCUMENTS_COUNT,
        files: null, 
        documents: null
    });

    const [tabValue, setTabValue] = useState(0);
    const [selectedRowIdx, setSelectedRowIdx] = useState(null);

    const [loadingFiles, setLoadingFiles] = useState(false);
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [selectedDocId, setSelectedDocId] = useState(null);
    const [pagination, setPagination] = useState({ filePageNumber: 1, docPageNumber: 1 });
    const selectedFileOrDoc = [selectedFile, selectedDocument].find(x => x.DocumentFK == selectedDocId)

    const sortedDocuments = _.orderBy(identifiedDocuments, ['DocumentTypeFk', 'Created'], ['asc', 'desc'])

    useEffect(() => {
        fetchClassifiers();
        return () => {
            setDocuments([])
        }
    }, []);

    useEffect(() => {
        const classifierId = _.get(config, 'Classifier.Id');
        if (!classifierId) {
            return
        }
        loadDocuments();
        loadFiles();
    }, [config.Classifier, config.CreatedFrom, config.CreatedTo])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const createFilter = (baseState, newValue, classifierId) => {
        const filter = {
            ...baseState,
            CreatedFrom: config.CreatedFrom,
            CreatedTo: config?.CreatedTo,
            ExcludedDocuments: jobExcludedDocs,
            MaxPagesPerFile: config.MaxPagesPerFile,
            ItemsPerPage: newValue || (classifierId ? config.IdentifiedDocumentsPerDocType : config.RandomFiles),
        };
        return filter;
    };
    
    const loadFiles = (newValue) => {
        const filter = createFilter(FILE_FILTER_DEFAULT_STATE, newValue);
    
        setLoadingFiles(true);
        fetchFiles(filter).then(() => {
            setLoadingFiles(false);
        });
    };
    const loadDocuments = (newValue) => {
        const classifierId = _.get(config, 'Classifier.Id');
        if (!classifierId) {
            return;
        }
        const filter = createFilter(DOCUMENT_FILTER_DEFAULT_STATE, newValue, classifierId);
        setLoadingDocuments(true);
        fetchDocuments({ classifierId, filter }).then(() => {
            setLoadingDocuments(false);
        });
    };

    const changeConfig = (propName, value) => {
        setConfig({ ...config, [propName]: value });
    }

    const handleNumberInput = (propName, value) => {
        const val = value.match(/(\d+)/);
        if (val) {
            const integerValue = +val[0]
            changeConfig(propName, integerValue)
            if (propName === 'RandomFiles') {
                debounceFunction(() => loadFiles(integerValue));
            } else if (propName === 'IdentifiedDocumentsPerDocType') {
                debounceFunction(() => loadDocuments(integerValue));
            }
        }
    }

    const createJobHandler = () => {
        const { Classifier, Description } = config;
        const Documents = identifiedDocuments.map(doc => ({
            DocumentId: doc.Id,
            DocumentTypeFK: doc.DocumentTypeFk,
            PagesCount: doc.PagesCount,
            Filename: null,
        }));
        const Files = randomFiles.map(file => ({
            DocumentId: file.Id,
            PagesCount: file.PagesCount,
            Filename: file.FileName,
        }))
        createJob({
            ProcessorFk: Classifier.Id,
            Description: Description,
            Documents,
            Files,
        });
        setShowCreate(false);
    }
    const excludeDocument = (documentId) => {
        const jobExcludedDocsCopy = _.cloneDeep(jobExcludedDocs);
        if (selectedDocId == documentId) {
            setSelectedDocId(null);
        }
        if (jobExcludedDocsCopy.includes(documentId)) {
            return;
        }
        jobExcludedDocsCopy.push(documentId);
        setJobExcludedDocs(jobExcludedDocsCopy);
    }

    const handleDateFilterChange = _.debounce((date, filterType) => {
        const parsedDate = dayjs(date);
        if (!parsedDate.isValid || parsedDate.year() < 2022) return;
        setConfig(prev => ({ ...prev, [filterType]: date  })) 
    }, 500);
    
    const renderDocumentsList = (documents, isFile) => documents.map((doc, idx) => {
        const { FileName, Id, DocumentTypeFk } = doc;
        const name = DocumentTypeFk
            ? _.get(documentTypes.find(x => x.Id === DocumentTypeFk), 'Name')
            : FileName;
        const rowColor = selectedRowIdx === idx
            ? Colors.PRIMARY_LIGHT_COLOR
            : idx % 2 === 1 ? Colors.GREY_HIGHLIGHT_COLOR : null;

        return <Box
            key={`${doc}-${idx}`}
            onClick={() => {
                setSelectedRowIdx(idx);
                setSelectedDocId(Id);
                fetchDocumentPreview({ documentFK: Id, isFile });
            }}
            sx={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center', background: rowColor }}
        >
            <Typography>{name}</Typography>
            <IconButton
                onClick={(event) => {
                    event.stopPropagation();
                    excludeDocument(Id)
                }}
                aria-label="delete"
                size="small"
            >
                <ClearIcon color="error" />
            </IconButton>
        </Box>
    })

    const loadPreviousNextFiles = (actionType) => {
        const { filePageNumber } = pagination;
        let filter = { ...FILE_FILTER_DEFAULT_STATE };
        if (actionType === "next") {
            const nextFilePageNumber = filePageNumber + 1;
            filter = createFilter(FILE_FILTER_DEFAULT_STATE, config.RandomFiles);
            filter.PageNumber = nextFilePageNumber;
            setConfig(prev => ({ ...prev, files: filter }));
            setPagination(prev => ({ ...prev, filePageNumber: nextFilePageNumber }));
        }
        if (actionType === "previous" && config.files) {
            const prevFilePageNumber = Math.max(1, filePageNumber - 1);
            filter = {
                ...config.files,
                PageNumber: prevFilePageNumber,
            };
            setConfig(prev => ({ ...prev, files: prevFilePageNumber === 1 ? null : filter }));
            setPagination(prev => ({ ...prev, filePageNumber: prevFilePageNumber }));
        }
        setLoadingFiles(true);
        fetchFiles(filter).then(() => setLoadingFiles(false));
    };

    const loadPreviousNextDocuments = (actionType) => {
        const { docPageNumber } = pagination;
        const classifierId = _.get(config, 'Classifier.Id');
        if (!classifierId) {
            return;
        }
        let filter = { ...DOCUMENT_FILTER_DEFAULT_STATE };
        if (actionType === "next") {
            const nextDocPageNumber = docPageNumber + 1;
            filter = createFilter(DOCUMENT_FILTER_DEFAULT_STATE, config.IdentifiedDocumentsPerDocType, classifierId);
            filter.PageNumber = nextDocPageNumber;
            setConfig(prev => ({ ...prev, documents: filter }));
            setPagination(prev => ({ ...prev, docPageNumber: nextDocPageNumber }));
        }
        if (actionType === "previous" && config.documents) {
            const prevDocPageNumber = Math.max(1, docPageNumber - 1);
            filter = {
                ...config.documents,
                PageNumber: prevDocPageNumber,
            };
            setConfig(prev => ({ ...prev, documents: prevDocPageNumber === 1 ? null : filter }));
            setPagination(prev => ({ ...prev, docPageNumber: prevDocPageNumber }));
        }
        setLoadingDocuments(true);
        fetchDocuments({ classifierId, filter }).then(() => setLoadingDocuments(false));
    };

    const handleNavigationButtons = (actionType, isFile) => {
        if (isFile) {
            loadPreviousNextFiles(actionType);
        } else {
            loadPreviousNextDocuments(actionType);
        }
    };

    const NavigationButtons = ({ onPrevious, onNext, config, isFile }) => {
        const isPreviousDisabled = isFile
            ? _.isNil(config?.files) && pagination.filePageNumber === 1
            : _.isNil(config?.documents) && pagination.docPageNumber === 1;

        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                    variant='contained'
                    onClick={onPrevious}
                    size='small'
                    sx={{ width: 80 }}
                    disabled={isPreviousDisabled}
                >
                    Previous
                </Button>
                <Button
                    variant='contained'
                    onClick={onNext}
                    size='small'
                    sx={{ width: 80 }}
                >
                    Next
                </Button>
            </Box>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Button sx={{ width: '200px' }} variant='customRounded' onClick={() => setShowCreate(false)} >Cancel</Button>
            <Box sx={{ display: 'inline-flex', gap: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%', minWidth: '316px' }}>
                <Box sx={{ width: '100%', minWidth: '316px', display: 'flex', flexDirection: 'column', gap: '15px', background: 'none' }}>
                        <Autocomplete
                            key="document-types-autocomplete"
                            size="small"
                            sx={{ width: '100%', marginRight: '1rem' }}
                            options={classifiers}
                            value={config.Classifier}
                            getOptionLabel={(option) => option.Name}
                            getOptionKey={(option) => option.ProcessorId}
                            renderInput={(params) => (
                                <TextField {...params} key={params.id} label="Classifier" variant="outlined" />
                            )}
                            onChange={(event, classifier) => changeConfig('Classifier', classifier)}
                        />
                        <TextField
                            size="small"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Description"
                            value={_.get(config, 'Description', '')}
                            onChange={(event) => changeConfig('Description', event.target.value)}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{ flex: 1 }}
                                    key="from-date-picker"
                                    label="Created From"
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={dayjs(config.CreatedFrom, 'YYYY-MM-DD')}
                                    onChange={(date) => handleDateFilterChange(_.isNil(date) ? dayjs().subtract(1, 'week').format('YYYY-MM-DD') : dayjs(date, 'YYYY-MM-DD'), 'CreatedFrom')}
                                    disabled={_.isNil(config.Classifier)}
                                    shouldDisableDate={(date) => date.year() < 2022}
                                />
                                <DatePicker
                                    sx={{ flex: 1 }}
                                    key="to-date-picker"
                                    label="Created To"
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={config?.CreatedTo ? dayjs(config.CreatedTo, 'YYYY-MM-DD') : null}
                                    onChange={(date) => handleDateFilterChange(_.isNil(date) ? null : dayjs(date, 'YYYY-MM-DD'), 'CreatedTo')}
                                    disabled={_.isNil(config.Classifier)}
                                    shouldDisableDate={(date) => date.year() < 2022}
                                />
                            </LocalizationProvider>
                        </Box>
                        <TextField
                            disabled={_.isNil(config.Classifier)}
                            size="small"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Max pages per file"
                            value={_.get(config, 'MaxPagesPerFile', 10)}
                            onChange={(event) => handleNumberInput('MaxPagesPerFile', event.target.value)}
                            type='number'
                        />
                        <TextField
                            disabled={_.isNil(config.Classifier)}
                            size="small"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Random files"
                            value={_.get(config, 'RandomFiles', 10)}
                            onChange={(event) => handleNumberInput('RandomFiles', event.target.value)}
                            type='number'
                        />
                        <TextField
                            disabled={_.isNil(config.Classifier)}
                            size="small"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Identified documents per Doc. Type"
                            value={_.get(config, 'IdentifiedDocumentsPerDocType', 10)}
                            onChange={(event) => handleNumberInput('IdentifiedDocumentsPerDocType', event.target.value)}
                            type='number'
                        />
                        <Button
                            disabled={_.isNil(config.Classifier)}
                            variant='contained'
                            onClick={createJobHandler}
                            size='small'
                            sx={{ width: 140 }}
                        >
                            Create Job
                        </Button>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%', minWidth: '216px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs  sx={{minHeight: '40px', height: '40px'}} value={tabValue} onChange={handleTabChange} aria-label="jobs tabs">
                    <Tab sx={{minHeight: '40px', height: '40px', width: '50%'}} label="Identified" {...aplyTabProps(0)} />
                            <Tab sx={{minHeight: '40px', height: '40px',  width: '50%'}} label="Random" {...aplyTabProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                        {config.Classifier
                            ? <NavigationButtons
                                isFile={false}
                                onPrevious={() => handleNavigationButtons("previous", false)}
                                onNext={() => handleNavigationButtons("next", false)}
                                config={config}
                            />
                            : null}
                        {loadingDocuments
                            ? <CircularProgress />
                            : config.Classifier
                                ? <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '2px',
                                    maxHeight: '700px',
                                    overflow: 'auto',
                                }}>
                                    {renderDocumentsList(sortedDocuments, false)}
                                </Box>
                                : null}
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        {config.Classifier
                            ? <NavigationButtons
                                isFile={true}
                                onPrevious={() => handleNavigationButtons("previous", true)}
                                onNext={() => handleNavigationButtons("next", true)}
                                previousFilter={config}
                            />
                            : null}
                        {loadingFiles
                            ? <CircularProgress />
                            : config.Classifier
                                ? <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '2px',
                                    maxHeight: '700px',
                                    overflow: 'auto',
                                }}>
                                    {renderDocumentsList(randomFiles, true)}
                                </Box>
                                : null}
                    </CustomTabPanel>
                </Box>

                <Box sx={{ width: '60%' }}>
                    {selectedFileOrDoc
                        ? <DocumentImageList
                            hideControlButtons={true}
                            images={selectedFileOrDoc.ScannedPages}
                            sharedCurrentPages={[]}
                            sharedIdentifyPages={[]}
                            propertyMap={'CdnUrl'}
                            isImageSelected={false}
                            keyMap={'ScannedPageId'}
                            pageNumberMap={'PageNumber'}
                            documentFK={selectedFileOrDoc?.DocumentFK}
                        />
                        : null}
                </Box>
            </Box>
        </Box>
    );
}