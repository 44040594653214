import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as JobThunks from './JobsThunk';
import * as JobSelectors from './selectors';
import * as SliceJob from './JobsSlice.js'
import { Box, Button } from '@mui/material';
import TableComponent from '../../../../Shared/Components/TableComponent';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import DeleteModal from 'Shared/Components/DeleteModal';

function _JobsList(props) {
    const {
        rows,
        fetchJobs,
        setShowCreate,
        startJob,
        resetJob,
        stopJob,
        cancelJob,
        setSelectedJob,
        deleteJob,
        getJobZipResult,
        selectedJob
    } = props;

    useEffect(() => {
        fetchJobs();
    }, [])
    let navigate = useNavigate();

    const [deletedJob, showDeleteModal] = useState(null);

    const openDeleteModal = (deletedJob) => {
        showDeleteModal(deletedJob);
    }
    const closeDeleteModal = () => {
        showDeleteModal(null);
    }
    const handleDeleteButtonClick = (deletedJob) => {
        if (!deletedJob.Id) return;
        deleteJob(deletedJob.Id)
        showDeleteModal(null);
    };

    const rowsWithActions = rows.map((row) => {
        const { Status, Id, Accuracy, ProcessorFk, HasZipResult } = row;
        return ({
            ...row,
            Actions: <Box>
                {['Created', 'Cancelled'].includes(Status)
                    ? <Button onClick={() => startJob(Id)}>
                        Start
                    </Button>
                    : null}
                {
                    Status === 'Error' 
                    ? <Button onClick={() => resetJob(Id)}>
                        Reset
                    </Button>
                    : null
                }
                {['Created'].includes(Status)
                    ? <Button onClick={() => openDeleteModal(row)}
                    >
                        Delete
                    </Button>
                    : null}
                {['Pending', 'ExtractingInProgress', 'Extracted', 'ClassifyingInProgress'].includes(Status)
                    ? <Fragment>
                        <Button onClick={() => stopJob(Id)}>
                            Stop
                        </Button>
                        <Button onClick={() => cancelJob(Id)}>
                            Cancel
                        </Button>
                    </Fragment>
                    : null}
                {
                    HasZipResult
                    ? <Fragment>
                        <Button onClick={() => getJobZipResult(Id)}>
                            Download Result
                        </Button>
                    </Fragment>
                    : null
                }
            </Box>,
            GoToValidate: <Box>
                {Status === 'Completed'
                    ? <Button
                        startIcon={!_.isNil(Accuracy) ? <VisibilityOutlinedIcon /> : null}
                        onClick={() => {
                            setSelectedJob({ ...selectedJob, Id: Id, Status: Status, ProcessorFk: ProcessorFk });
                            navigate(`/jobs/validation/${Id}`);
                        }}>
                        {!_.isNil(Accuracy) ? 'Preview' : 'Validate'}
                    </Button>
                    : Status !== 'Creating'
                        ? <Button
                            startIcon={<VisibilityOutlinedIcon />}
                            onClick={() => {
                                setSelectedJob({ Id: Id });
                                navigate(`/jobs/validation/${Id}`);
                            }}
                        >
                            Preview
                        </Button>
                        : null}
            </Box >
        })
    })

    return (<Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <Button sx={{ width: '200px' }} variant='customRounded' onClick={() => {
            if (_.isFunction(setShowCreate)) {
                setShowCreate(true)
            }
        }}>
            Create New
        </Button>
        <TableComponent
            columns={columns}
            rows={rowsWithActions}
            enableTablePagination={false}
            handleSelectedRow={_.noop}
            handleRowItemClick={_.noop}
        />
        <Box>
            {deletedJob
                ? <DeleteModal
                    isOpen={deletedJob !== null}
                    onClose={closeDeleteModal}
                    onConfirm={() => handleDeleteButtonClick(deletedJob)}
                    itemType="Job"
                    itemDescription={deletedJob?.Description}
                />
                : null}
        </Box>
    </Box>)
}

const mapStateToProps = (state, ownProps) => {
    const jobs = JobSelectors.getJobs(state);
    const selectedJob = JobSelectors.getSelectedJob(state);
    const jobsArr = jobs.map((job) => {
        const { Created, Started, Finished } = job;
        const CreatedDate = new Date(Created).toLocaleDateString();
        const StartedDate = Started ? new Date(Started).toLocaleDateString() : '';
        const FinishedTimeMs = _.isNil(Finished) ? null : new Date(Finished) - new Date(Started);
        const FinishedTimeMins = FinishedTimeMs ? (FinishedTimeMs / (1000 * 60)).toFixed(2) : '';
        return ({
            ...job,
            CreatedDate,
            StartedDate,
            FinishedTime: FinishedTimeMins,
        })
    })
    const rows = _.orderBy(jobsArr, ['Created'], ['desc'])
    return {
        rows,
        selectedJob
    }
}

const mapDispatchToProps = {
    getJobZipResult: JobThunks.getJobZipResult,
    fetchJobs: JobThunks.fetchJobs,
    startJob: JobThunks.startJob,
    resetJob: JobThunks.resetJob,
    stopJob: JobThunks.stopJob,
    cancelJob: JobThunks.cancelJob,
    setSelectedJob: SliceJob.setSelectedJob,
    deleteJob: JobThunks.deleteJob
}

export const JobsList = connect(mapStateToProps, mapDispatchToProps)(_JobsList);

const columns = [
    {
        name: 'Id',
        value: 'Id',
        filterable: false,
    },
    {
        name: 'Status',
        value: 'Status',
        filterable: true,
        filter: (x, value) => (x.Status || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Description',
        value: 'Description',
        filterable: true,
        filter: (x, value) => (x.Description || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Created',
        value: 'CreatedDate',
        filterable: true,
        filter: (x, value) => (x.Created || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Started',
        value: 'StartedDate',
        filterable: true,
        filter: (x, value) => (x.Created || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Finished (mins)',
        value: 'FinishedTime',
        filterable: true,
        filter: (x, value) => (x.Created || '').toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        name: 'Classifier',
        value: 'ProcessorFk',
        filterable: true,
        filter: (x, value) => x.ProcessorFk.toString().includes(value?.toString()),
    },
    {
        name: 'Actual',
        value: 'Actual',
        filterable: true,
        filter: (x, value) => !_.isNil(x.Actual) && x.Actual.toString().includes(value?.toString()),
    },
    {
        name: 'Recognized',
        value: 'Recognized',
        filterable: true,
        filter: (x, value) => !_.isNil(x.Recognized) && x.Recognized.toString().includes(value?.toString()),
    },
    {
        name: 'Not Recognized',
        value: 'NotRecognized',
        filterable: true,
        filter: (x, value) => !_.isNil(x.NotRecognized) && x.NotRecognized.toString().includes(value?.toString()),
    },
    {
        name: 'Incorrect',
        value: 'Incorrect',
        filterable: true,
        filter: (x, value) => !_.isNil(x.Incorrect) && x.Incorrect.toString().includes(value?.toString()),
    },
    {
        name: 'Accuracy',
        value: 'Accuracy',
        filterable: true,
        filter: (x, value) => !_.isNil(x.Accuracy) && x.Accuracy.toString().includes(value?.toString()),
    },
    {
        align: 'left',
        name: 'Actions',
        value: 'Actions',
    },
    {
        align: 'left',
        name: 'Validate',
        value: 'GoToValidate',
    }
]
