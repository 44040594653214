import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import _ from 'lodash';
import { debounceFunction } from '../DataSamples/DocumentTypeSamples';
import { JobRunnerAutoPreview } from './JobRannerAutoPreview';

const initialConfig = {
    Classifier: null,
    Description: '',
    PctValidSamples: 90
};

export function JobRunnerAuto({
    classifiers,

    setShowCreate,
    createJob,
    jobPreview,
    calculateJobPreview,
    editedJobPreview,
    setEditedJobPreview
}) {
    const [config, setConfig] = useState(initialConfig);
    const tempValueRef = useRef(_.get(config, 'Description') || '');

    useEffect(() => {
        if (_.isNil(config?.Classifier)) return;
        debounceFunction(() => calculateJobPreview(config));
    }, [config.Classifier, config.PctValidSamples]);

    const changeConfigMutiple = (update, shouldResetPreview = true) => {
        const newConfig = { ...config, ...update };
        setConfig(newConfig);
        if (shouldResetPreview) {
            setEditedJobPreview([]);
        }
    };

    const handleNumberInput = (propName, value) => {
        if (_.isNil(value)) return;
        const val = value?.match(/(\d+)/);
        const integerValue = +val?.[0];
        changeConfigMutiple({ [propName]: integerValue || null });
    };

    const documentTypeSamples = _.get(jobPreview, 'DocumentTypeSamples') || {};

    const getAllSampleIds = (documentTypeSamples) => {
        const allSampleIds = [];

        for (const [DocumentTypeId, { SampleIds }] of Object.entries(documentTypeSamples)) {
            const editedField = editedJobPreview && editedJobPreview.find(x => x.DocumentTypeId === parseInt(DocumentTypeId));
            if (editedField && editedField.isSelected) {
                const Take = editedField.Take;
                const selectedSamples = SampleIds.slice(0, Take);
                allSampleIds.push(...selectedSamples);
            }
        }

        return allSampleIds;
    };

    const selectedSamplesIds = !_.isEmpty(documentTypeSamples)
        ? !_.isEmpty(editedJobPreview)
            ? getAllSampleIds(documentTypeSamples)
            : Object.values(documentTypeSamples).flatMap(info => info.SampleIds)
        : [];

    const createJobHandler = () => {
        const { ProcessorFk, Description, PctValidSamples } = config;
        if (!ProcessorFk || !PctValidSamples || !jobPreview) return;

          createJob({
              ProcessorFk: ProcessorFk,
              Description: Description || '',
              PctValidSamples: PctValidSamples,
              DataSamplesIds: selectedSamplesIds
          });
         setShowCreate(false);
    }
    const handleBlur = () => {
        changeConfigMutiple({ Description: tempValueRef.current }, false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Button sx={{ width: '200px' }} variant='customRounded' onClick={() => setShowCreate(false)}>Cancel</Button>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '25%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', background: 'none' }}>
                        <Autocomplete
                            key="document-types-autocomplete"
                            size="small"
                            options={classifiers}
                            value={config.Classifier ? classifiers.find(c => c.Name === config.Classifier) || null : null}
                            getOptionLabel={(option) => option?.Name || ''}
                            isOptionEqualToValue={(option, value) => option?.Name === value?.Name}
                            renderInput={(params) => (
                                <TextField {...params} key={params.id} label="Classifier" variant="outlined" />
                            )}
                            onChange={(event, classifier, reason) => {
                                if (reason === 'clear') {
                                    setConfig(initialConfig);
                                }
                                if (classifier) {
                                    changeConfigMutiple({ Classifier: classifier?.Name, ProcessorFk: classifier?.Id, Description: initialConfig.Description, PctValidSamples: initialConfig.PctValidSamples });
                                }
                            }}
                        />
                        <TextField
                            size="small"
                            variant="outlined"
                            label="Description"
                            defaultValue={tempValueRef.current}
                            onBlur={handleBlur}
                            onChange={(event) => {
                                tempValueRef.current = event.target.value;
                            }}
                        />
                        <TextField
                            disabled={_.isNil(config.Classifier)}
                            size="small"
                            variant="outlined"
                            label={`${_.get(config, 'PctValidSamples') || 0}% Job's validated samples, `}
                            value={_.get(config, 'PctValidSamples') || null}
                            onChange={(event) => {
                                const value = event.target.value;
                                if (value >= 1 && value <= 100) {
                                    handleNumberInput('PctValidSamples', value);
                                }
                            }}
                            type='number'
                            inputProps={{
                                min: 0,
                                max: 100,
                            }}
                        />
                        <Button
                            disabled={_.isNil(config.Classifier) || _.isNil(config.PctValidSamples) || _.isEmpty(selectedSamplesIds)}
                            variant='contained'
                            onClick={createJobHandler}
                            size='small'
                            sx={{ width: 140 }}
                        >
                            Create Job
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ width: '70%' }}>
                    {!_.isNil(config.Classifier)
                        ? <JobRunnerAutoPreview
                        jobPreview={jobPreview}
                        editedJobPreview={editedJobPreview}
                        setEditedJobPreview={setEditedJobPreview}
                        config={config}
                    />
                        : null}
                </Box>
            </Box>
        </Box>
    );
}