import React, { useState } from 'react';
import * as Thunks from '../../Api/thunk';
import { connect } from 'react-redux';
import { Box, Tabs, Tab } from '@mui/material';
import _ from 'lodash';
import * as JobThunks from './JobsThunk';
import * as Selectors from '../../Api/selectors';
import * as JobSlice from './JobsSlice';
import * as JobSelectors from './selectors';
import { JobRunnerManual } from './JobRunnerManual';
import { JobRunnerAuto } from './JobRunnerAuto';

function JobRunner({
    fetchClassifiers,
    classifiers,
    identifiedDocuments,
    randomFiles,
    fetchDocumentPreview,
    selectedFile,
    selectedDocument,
    jobExcludedDocs,

    fetchFiles,
    fetchDocuments,
    setShowCreate,
    createJob,
    setJobExcludedDocs,
    documentTypes,
    setDocuments,
    calculateJobPreview,
    jobPreview,
    editedJobPreview,
    setEditedJobPreview
}) {
    const [activeTab, setActiveTab] = useState('manual');

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box>
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Create Manual" value="manual" />
                <Tab label="Create Auto" value="auto" />
            </Tabs>
            <Box sx={{ marginTop: '1cm' }}>
                {activeTab === 'manual'
                    ? <JobRunnerManual
                        fetchClassifiers={fetchClassifiers}
                        classifiers={classifiers}
                        identifiedDocuments={identifiedDocuments}
                        randomFiles={randomFiles}
                        fetchDocumentPreview={fetchDocumentPreview}
                        selectedFile={selectedFile}
                        selectedDocument={selectedDocument}
                        jobExcludedDocs={jobExcludedDocs}
                        fetchFiles={fetchFiles}
                        fetchDocuments={fetchDocuments}
                        setShowCreate={setShowCreate}
                        createJob={createJob}
                        setJobExcludedDocs={setJobExcludedDocs}
                        documentTypes={documentTypes}
                        setDocuments={setDocuments}
                    />
                    : activeTab === 'auto'
                        ? <JobRunnerAuto
                            classifiers={classifiers}
                            setShowCreate={setShowCreate}
                            createJob={createJob}
                            calculateJobPreview={calculateJobPreview}
                            jobPreview={jobPreview}
                            editedJobPreview={editedJobPreview}
                            setEditedJobPreview={setEditedJobPreview}
                        />
                        : null}
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const classifiers = Selectors.getClassifiers(state);
    const allIdentifiedDocuments = JobSelectors.getDocuments(state);
    const allRandomFiles = Selectors.getFiles(state);
    const selectedFile = Selectors.getSelectedFile(state);
    const selectedDocument = Selectors.getSelectedDocument(state);
    const jobExcludedDocs = JobSelectors.getJobExcludedDocs(state);
    const identifiedDocuments = allIdentifiedDocuments.filter(doc => !jobExcludedDocs.includes(doc.Id));
    const randomFiles = allRandomFiles.filter(file => !jobExcludedDocs.includes(file.Id));
    const documentTypes = Selectors.getDocumentTypes(state);
    const jobPreview = JobSelectors.getJobPreview(state);
    const editedJobPreview = JobSelectors.getEditedJobPreview(state);
    return {
        classifiers,
        identifiedDocuments,
        randomFiles,
        selectedFile,
        selectedDocument,
        jobExcludedDocs,
        documentTypes,
        jobPreview,
        editedJobPreview
    }
}

const mapDispatchToProps = {
    fetchClassifiers: Thunks.fetchClassifiers,
    fetchDocumentPreview: Thunks.fetchDocumentPreview,
    fetchFiles: Thunks.fetchFiles,
    fetchDocuments: JobThunks.fetchDocuments,
    createJob: JobThunks.createJob,
    setJobExcludedDocs: JobSlice.setJobExcludedDocs,
    setDocuments: JobSlice.setDocuments,
    calculateJobPreview: JobThunks.calculateJobPreview,
    setEditedJobPreview: JobSlice.setEditedJobPreview
}

export default connect(mapStateToProps, mapDispatchToProps)(JobRunner);
