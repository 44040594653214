import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Modal, TextField } from '@mui/material';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import * as Thunks from '../../Api/thunk';
import * as SliceActions from '../../Api/slice';
import * as Selectors from '../../Api/selectors';
import _ from 'lodash';
import DocumentTypesGrid from './DocumentTypesGrid';
import DocumentTypeSamples from './DocumentTypeSamples';


export const tabs = ['Samples', 'Selected']

function DataSamples(props) {
    const {
        setSelectedDataSamples,
        selectedDataSamples,
        saveDataSet,
        setSampleFilter,
        sampleFilter
    } = props;

    const navigate = useNavigate();
    let { docTypeFk = null } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [dataSetName, setDataSetName] = useState('');
    const [dataSetNameError, setDataSetNameError] = useState(false);
    const [dataSetVersion, setDataSetVersion] = useState(1.0);
    const [dataSetVersionError, setDataSetVersionError] = useState(false);

    useEffect(() => {
        const updatedSampleFilter = {
            docTypeFk: docTypeFk
        };
        setSampleFilter(updatedSampleFilter);
    }, []);

    const changeFilter = (filterOptions) => {
        const updatedSampleFilter = {
            docTypeFk: filterOptions?.docTypeFk || docTypeFk,
            PageNumber: filterOptions?.PageNumber,
            ItemsPerPage: filterOptions?.ItemsPerPage
        };
        setSampleFilter(updatedSampleFilter);
    };

    const handleCloseModal = () => setOpenModal(false);

    const handleOpenModalDataSet = () => {
        setOpenModal(true);
    };

    const handleDataSetNameChange = (event) => {
        if (event.target.value.trim() !== '') {
            setDataSetNameError(false);
        }

        setDataSetName(event.target.value);
    }

    const handleDataSetVersionChange = (event) => {
        if (event.target.value.trim() !== '') {
            setDataSetVersionError(false);
        }

        setDataSetVersion(event.target.value);
    }

    const handleSaveDataSet = () => {
        if (!dataSetName) {
            setDataSetNameError(true);
            return;
        }

        if(!/^[a-zA-Z0-9](-*[a-zA-Z0-9])*$/.test(dataSetName)) {
            setDataSetNameError(true);
            return;
        }

        if (!dataSetVersion) {
            setDataSetVersionError(true);
            return;
        }

        const dataSet = {
            name: dataSetName,
            version: dataSetVersion,
            aiDataSamples: selectedDataSamples.map(x => x.Id)
        }

        saveDataSet(dataSet).then(() => {
            setSelectedDataSamples([]);
            navigate('/documents/sets');
        });

        setOpenModal(false);
    }

    const handleCancelDataSet = () => {
        setOpenModal(false);
    }

    const isDataSamplesCollectionValid = () => {
        const groupedArray = _.cloneDeep(selectedDataSamples).reduce((result, obj) => {
            const key = obj.DocumentTypeFK;
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(obj);
            return result;
        }, {});
        const isSamplesCountValid = Object.values(groupedArray).every(ar => ar.length >= 20);
        return Object.keys(groupedArray).length >= 2 && isSamplesCountValid;
    }

    const renderModal = () => (<Modal
        open={openModal}
        onClose={handleCloseModal}
    >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <TextField
                label='Data Set name'
                variant='outlined'
                size='small'
                value={dataSetName}
                onChange={handleDataSetNameChange}
                error={dataSetNameError}
                helperText={dataSetNameError ? 'the field is empty or does not match the ^[a-zA-Z0-9](-*[a-zA-Z0-9])*$ pattern' : null}
            />
            <TextField
                sx={{ marginTop: '1rem' }}
                label='Data Set version'
                variant='outlined'
                type="number"
                size='small'
                value={dataSetVersion}
                onChange={handleDataSetVersionChange}
                error={dataSetVersionError}
                helperText={dataSetVersionError ? 'field is required' : null}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '0.5rem' }}>
                <Button
                    variant='customRoundedText'
                    size='small'
                    sx={{
                        color: 'error.main',
                        ":hover": { backgroundColor: 'error.main' }
                    }}
                    onClick={() => handleCancelDataSet()}
                >
                    Cancel
                </Button>
                <Button
                    variant='customRoundedText'
                    size='small'
                    onClick={() => handleSaveDataSet()}
                >
                    Save
                </Button>
            </Box>
        </Box>
    </Modal>
    )

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {renderModal()}
            <Box style={{ width: '100%', display: 'inline-flex', height: '100%' }}>
                {docTypeFk
                    ? <DocumentTypeSamples {...{ sampleFilter, changeFilter }} />
                    : <DocumentTypesGrid {...{ sampleFilter, changeFilter }} />}
            </Box>
            <Box sx={{ position: 'fixed', bottom: '4.8rem', right: '1.5rem' }}>
                <Button
                    onClick={() => {
                        handleOpenModalDataSet()
                    }}
                    disabled={!isDataSamplesCollectionValid()}
                    variant="customRounded"
                    startIcon={<DatasetLinkedIcon />}
                >
                    Add DataSet (2 types x 20 samples min)
                </Button>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const dataSamples = Selectors.getDataSamples(state); 
    const dataSamplesCount = Selectors.getDataSamplesCount(state);
    const documentTypes = Selectors.getDocumentTypes(state);
    const selectedDocumentPages = Selectors.getSelectedPages(state);
    const samplesPagination = Selectors.getSamplesPagination(state);
    const selectedDataSamples = Selectors.getSelectedDataSamples(state);
    const selectedSampleId = _.get(Selectors.getCurrentSample(state), 'Id') || -1;
    const sampleFilter = Selectors.getDocSampleFilter(state);

    return {
        dataSamples,
        documentTypes,
        selectedDocumentPages,
        samplesPagination,
        selectedDataSamples,
        selectedSampleId,
        dataSamplesCount,
        sampleFilter
    };
};

const mapDispatchToProps = {
    fetchDataSamplesWithFilter: Thunks.fetchDataSamplesWithFilter,
    fetchDataSamplesView: Thunks.fetchDataSamplesView,
    deleteDataSample: Thunks.deleteDataSample,
    saveDataSet: Thunks.saveDataSet,
    setSamplesPagination: SliceActions.setSamplesPagination,
    setSelectedDocumentType: SliceActions.setSelectedDocumentType,
    setSelectedDocumentFilter: SliceActions.setSelectedDocumentFilter,
    addSelectedDataSample: SliceActions.addSelectedDataSample,
    setSelectedDataSamples: SliceActions.setSelectedDataSamples,
    deselectDataSamplesByDocTypeFk: SliceActions.deselectDataSamplesByDocTypeFk,
    getDataSamplePreview: Thunks.getDataSamplePreview,
    setSampleFilter: SliceActions.setSampleFilter
    
}
export default connect(mapStateToProps, mapDispatchToProps)(DataSamples);
