import React, { useState, useEffect, useRef } from "react";
import { Box, Modal, IconButton } from "@mui/material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CloseIcon from '@mui/icons-material/Close';
import { ImageItem } from "./ImageItem";

function PreviewImageList({
    propertyMap,
    keyMap,
    handleImageSelection,
    selectedDocumentPages,
    renderToolbar,
    selectedJobFile,
    renderLabelingModule
}) {
    const [selectedImage, showScaleImage] = useState(null);
    const [modalImageRotation, setModalImageRotation] = useState(0);
    const containerRef = useRef(null);
    const docFk = selectedDocumentPages[0]?.DocumentFK || selectedDocumentPages[0]?.Id
    
    useEffect(() => {
      if (containerRef && containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'auto' });
      }
    }, [docFk]);

    const openImageModal = (image) => {
        showScaleImage(image);
    };

    const closeImageModal = () => {
        showScaleImage(null);
    };

    const rotateImageRight = () => {
        setModalImageRotation(modalImageRotation + 90);
    }

    const rotateImageLeft = () => {
        setModalImageRotation(modalImageRotation - 90);
    }
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                width: "100%",
                maxHeight: "calc(100vh - 150px)",
            }}
        >
            <Box>
                {selectedImage && (
                    <Modal open={selectedImage !== null}>
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: "auto",
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignSelf: "center",
                                    gap: "1rem",
                                }}
                            >
                                <IconButton
                                    onMouseDown={rotateImageLeft}
                                    sx={{ mr: "1rem" }}
                                >
                                    <RotateRightIcon fontSize="large" />
                                </IconButton>
                            </Box>
                            <Box
                                sx={{
                                    maxWidth: "100dvw",
                                    maxHeight: "98dvh",
                                    display: "flex",
                                    justifyContent: "center",
                                    overflow: "hidden",
                                }}
                            >
                                <img
                                    onMouseDown={closeImageModal}
                                    style={{
                                        objectFit: "contain",
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        transform: `rotate(${modalImageRotation}deg)`,
                                    }}
                                    loading="lazy"
                                    src={selectedImage[propertyMap]}
                                    alt={selectedImage[keyMap]}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignSelf: "center",
                                    gap: "1rem",
                                }}
                            >
                                <IconButton
                                    sx={{
                                        position: "fixed",
                                        top: "1rem",
                                        ml: "1rem",
                                    }}
                                    onMouseDown={closeImageModal}
                                >
                                    <CloseIcon
                                        fontSize="large"
                                        sx={{ color: "#343333" }}
                                    />
                                </IconButton>
                                <IconButton
                                    onMouseDown={rotateImageRight}
                                    sx={{ ml: "1rem" }}
                                >
                                    <RotateLeftIcon fontSize="large" />
                                </IconButton>
                            </Box>
                        </Box>
                    </Modal>
                )}
                <Box ref={containerRef}></Box>
                {selectedDocumentPages.map((document, index) => (
                    <Box key={`docId-${index}`}>
                        {document.ScannedPages.map((image, idx) => {
                            return (
                                <ImageItem key={`imageFullsize-${image[keyMap]}-${idx}`}
                                    {...{
                                        image, keyMap, idx, propertyMap, openImageModal,
                                        renderLabelingModule, selectedJobFile, renderToolbar
                                    }}>
                                </ImageItem>
                            )
                        })}
                    </Box>

                ))}

            </Box>
        </Box>
    );

}

export default PreviewImageList;
