import _ from 'lodash';
import { JOB_ROOT_KEYS } from './JobsSlice';
const localState = (state) => _.get(state, 'jobs');

export const getJobs = (state) => _.get(localState(state), 'jobs') || [];

export const getSelectedJob = (state) => _.get(localState(state), 'selectedJob') || { Id: null, Files: [] };
export const getJobExcludedDocs = (state) =>  _.get(localState(state), 'jobExcludedDocs') || [];
export const getJobFiles = (state) =>  _.get(localState(state), 'selectedJob.Files') || [];
export const getSelectedJobFile = (state) => _.get(localState(state), 'selectedJobFile') || {};
export const getDocuments = (state) => _.get(localState(state), 'documents') || [];
export const getError = (state) => _.get(localState(state), 'error');
export const getJobPreview = (state) => _.get(localState(state), JOB_ROOT_KEYS.PREVIEW_JOB);
export const getEditedJobPreview = (state) => _.get(localState(state), 'editedJobPreview') || [];
// export const getIdentifiedDocuments = (state) => _.get(localState(state), 'identifiedDocuments') || [1, 2,3 , 4];
// export const getRandomFiles = (state) => _.get(localState(state), 'randomFiles') || [2];