import {connect} from 'react-redux';
import {Box, Button, Menu, MenuItem, CircularProgress} from '@mui/material';
import * as Thunks from '../../Api/thunk';
import * as Selectors from '../../Api/selectors';
import * as SliceActions from '../../Api/slice';
import React, {useEffect, useState} from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SendIcon from '@mui/icons-material/Send';
import _ from "lodash";
import TableComponent from "../../../../Shared/Components/TableComponent";
import DeleteItemDialog from 'Widgets/DeleteItemDialog';
import {useNavigate} from 'react-router-dom';

const tableColumns = [
    {
        name: 'Set Name',
        value: 'Name',
        align: 'left',
    },
    {
        name: 'Version',
        value: 'Version',
        align: 'left',
    },
    {
        name: 'Submitted',
        value: 'IsSubmitted',
        align: 'left',
    },
    {
        name: 'Progress',
        value: 'ReadinessFactor',
        align: 'left',
    },
    {
        name: 'Document Types',
        value: 'DocumentTypes',
        align: 'left',
    },
    {
        name: 'Bad Samples Count',
        value: 'BadSamplesCount',
        align: 'left'
    },
    {
        name: 'Created',
        value: 'Created',
        align: 'left',
    },
    {
        name: 'Actions',
        value: 'Actions',
        align: 'left',
    },
];

function DataSets({
                      fetchDataSets,
                      dataSets,
                      deleteDataSet,
                      setSelectedDataSet,
                      selectedDataSet,
                      submitTrainClassifier,
                  }) {

    const navigate = useNavigate();
    const [loadingDataSets, setLoadingDataSets] = useState(false);
    const [loadingClassifier, setLoadingClassifier] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openTrainMenu = Boolean(anchorEl);

    const [openDeletingDialog, setOpenDeletingDialog] = useState(false);
    const [deletingDialogData, setDeletingDialogData] = useState(null);

    useEffect(() => {
        setLoadingDataSets(true);
        fetchDataSets().then(() => {
            setLoadingDataSets(false);
        });
    }, [fetchDataSets]);

    const handleDeleteProcessorClick = (item) => {
        setDeletingDialogData({deleteAction: () => deleteDataSet(item.Id), itemName: item.Name});
        setOpenDeletingDialog(true);
    }

    const transformedDataSets = () => {
        if (_.isArray(dataSets)) {
            return dataSets.map((x, idx) => {
                const createdDate = new Date(x.Created);
                const formattedCreatedDate = `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`;

                return {   
                    Id: x.Id,
                    Name: x.Name,
                    Version: x.Version,
                    IsSubmitted: x.IsSubmitted ?
                        <CheckOutlinedIcon sx={{color: 'primary.main'}}/> :
                        <CloseOutlinedIcon sx={{color: 'primary.main'}}/>,
                    ReadinessFactor: (x.ReadinessFactor*100).toFixed() + "%",
                    DocumentTypes: x.DocumentTypes?.join(','),
                    Status: x.Status,
                    BadSamplesCount: _.get(x, 'BadSamples.length', 0),
                    Created: formattedCreatedDate,
                    Actions: 
                        <Box
                            key={`list-row-key-${idx}`}    
                        >
                            {!x.IsSubmitted && 
                                <Box>
                                    <Button variant="customRoundedTextDanger"
                                        onClick={() => handleDeleteProcessorClick(x)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            }
                        </Box>
                }
            })
        }

        return [];
    }

    const handleTrainClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleSelectedRow = (row) => {
        return row.Id === _.get(selectedDataSet, 'Id');
    }

    const handleRowItemClick = (row) => {
        const originalSelectedDataSet = dataSets.find(dataSet => dataSet.Id === row.Id);
        setSelectedDataSet(originalSelectedDataSet);
    }

    const handleTrainClassifier = () => {
        setLoadingClassifier(true);

        submitTrainClassifier(selectedDataSet.Id).then(() => {
            setLoadingClassifier(false);
            navigate('/documents/classifiers');
        });
    }

    const handleTrainRecognizer = () => {
        navigate('/documents/recognizers/entity-types');
    }

    return (
        <Box>
            {loadingDataSets ?
                <CircularProgress/> :
                <TableComponent
                    columns={tableColumns}
                    rows={transformedDataSets()}
                    enableTablePagination={true}
                    handleSelectedRow={handleSelectedRow}
                    handleRowItemClick={handleRowItemClick}
                />}
            <DeleteItemDialog
                openDialog={openDeletingDialog}
                setOpenDialog={setOpenDeletingDialog}
                {...deletingDialogData}
            />
            <Box sx={{ position: 'fixed', bottom: '4.8rem', right: '1.5rem' }}>
                {loadingClassifier
                    ? <CircularProgress/>
                    :   <Box>
                            <Button
                                onClick={handleTrainClick}
                                disabled={_.isEmpty(selectedDataSet)
                                    || selectedDataSet.ReadinessFactor !== 1
                                }
                                variant="customRounded"
                                startIcon={<SendIcon/>}
                                sx={{mr: '1em'}}
                            >
                                Train
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={openTrainMenu}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                                }}
                            >
                                <MenuItem onClick={handleTrainClassifier}>Classifier</MenuItem>
                                <MenuItem onClick={handleTrainRecognizer}>Recognizer</MenuItem>
                            </Menu>
                        </Box>
                    }
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const dataSets = Selectors.getDataSets(state);
    const selectedDataSet = Selectors.getSelectedDataSet(state);

    return {
        dataSets,
        selectedDataSet
    }
}

const mapDispatchToProps = {
    fetchDataSets: Thunks.fetchDataSets,
    deleteDataSet: Thunks.deleteDataSet,
    submitTrainClassifier: Thunks.submitTrainClassifier,
    setSelectedDataSet: SliceActions.setSelectedDataSet,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataSets);
