import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Checkbox, Tooltip, TextField, Button } from '@mui/material';
import * as Thunks from '../../Api/thunk';
import * as SliceActions from '../../Api/slice';
import * as Selectors from '../../Api/selectors';
import _ from 'lodash';
import TableComponent from '../../../../Shared/Components/TableComponent';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Papa from 'papaparse';

function DocumentTypesGrid(props) {
    const {
        setSelectedDataSamples,
        documentTypes,
        selectedDataSamples,
        fetchDataSampleCreationStatistics,
        fetchDataSamplesView,
        dataSamplesCount,
        selectDataSamples,
        deselectDataSamplesByDocTypeFk,
        selectedSampleId,
        dataSamplesStatistics,
        setDataSamples,
        sampleFilter,
        changeFilter,
        setSampleFilter
    } = props;

    const navigate = useNavigate();

    useEffect(() => {
        fetchDataSamplesView(sampleFilter)
        setSampleFilter({
            Id: null,
            Name: null,
            DocumentTypeFK: null,
            Status: null
        })
    }, [])

    useEffect(() => {
        if (_.isArray(dataSamplesStatistics))
            {
                const csv = Papa.unparse(dataSamplesStatistics);

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'dataSampleCreationStatistics.csv'; 
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            }
    }, [dataSamplesStatistics])

    const validateSelectedSamplesPercent = (e) => {
        if (e.target.value > 100)
            e.target.value = 100;
        if (e.target.value < 1) 
            e.target.value = 1;
        return e.target.value;
    }

    const changeSelectedSamplesCount = (e, validatedCount, docTypeFk) => {
        const selectedSamplesPercent = validateSelectedSamplesPercent(e);
        if (selectedSamplesPercent < Number(e.target.defaultValue)) {
            const validatedSamplesCount = Math.round(validatedCount*(selectedSamplesPercent/100));
            setSelectedDataSamples(
                [...selectedDataSamples.filter(x => x.DocumentTypeFK != docTypeFk),
                ...selectedDataSamples.filter(x => x.DocumentTypeFK == docTypeFk).slice(0, validatedSamplesCount)]
            );
        } else {
            selectDataSamples({
                docTypeFk: docTypeFk, 
                samplesCount: validatedCount
            })
        }
    }

    function getRows() {
        if (_.isEmpty(documentTypes)) {
            return [];
        }

        return Object.entries(dataSamplesCount)
            .map(([key, value]) => {
                const docType = documentTypes.find(x => x.Id == key);
                const selectedSamplesPerDocType = selectedDataSamples.filter(x => x.DocumentTypeFK == key && x.Status === "Validated");
                const selectedAmount = selectedSamplesPerDocType.length;
                const isSelectedAmountPositive = selectedAmount > 0;
                return {
                    key: key,
                    Id: key,
                    Amount: value.TotalCount,
                    SelectedSamplesCount:
                        (<Tooltip key={key} onClick={(e) => { e.stopPropagation() }}>
                            <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <TextField
                                    onClick={(e) => { e.stopPropagation() }}
                                    variant="standard" 
                                    type="number"
                                    onBlur={(e) => changeSelectedSamplesCount(e, value.ValidatedCount, key)}
                                    defaultValue={Math.round((selectedAmount * 100)/value.ValidatedCount)}
                                    disabled={!isSelectedAmountPositive}
                                />
                                </Box>
                        </Tooltip>), 
                    Selected:
                        (<Tooltip key={key} title='Select Sample' onClick={(e) => { e.stopPropagation() }}>
                            <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Checkbox
                                    size='small'
                                    indeterminate={isSelectedAmountPositive}
                                    onClick={() => isSelectedAmountPositive 
                                        ? deselectDataSamplesByDocTypeFk(key) 
                                        : selectDataSamples({
                                            docTypeFk: key, 
                                            samplesCount: value.ValidatedCount
                                        })}
                                />
                            </Box>
                        </Tooltip>),
                    Name:
                        <Link onClick={() => {
                            setDataSamples([]);
                            changeFilter({docTypeFk: docType.Id, PageNumber: 1, ItemsPerPage: 100})
                            navigate(`/documents/samples/${docType.Id}`)
                        }}>
                            {docType.Name}
                        </Link>,
                    Validated: value.ValidatedCount
                }
            })
    }
    const handleSelectedRow = (row) => {
        return row.Id === selectedSampleId;
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', float: 'right', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.4rem'}}>
                <Box style={{ display: 'inline-flex', gap: 20 }}>
                    <Link>Document Types </Link>
                </Box>
                <Button
                    startIcon={<DownloadForOfflineIcon/>}
                    variant="customRounded"
                    size="small"
                    onClick={() => fetchDataSampleCreationStatistics()}
                >
                    Get Creation Statistics
                </Button>
            </Box>
            <TableComponent
                columns={columns}
                rows={getRows()}
                handleRowItemClick={() => {}}
                handleSelectedRow={handleSelectedRow}
                selectedSampleId={selectedSampleId}
            />
        </Box>
    )
}

const mapStateToProps = (state) => {
    const dataSamplesCount = Selectors.getDataSamplesCount(state);
    const documentTypes = Selectors.getDocumentTypes(state);
    const dataSamplesStatistics = Selectors.getDataSamplesStatistics(state);
    const selectedDataSamples = Selectors.getSelectedDataSamples(state);
    const selectedSampleId = _.get(Selectors.getCurrentSample(state), 'Id') || -1;
    return {
        dataSamplesStatistics,
        documentTypes,
        selectedDataSamples,
        dataSamplesCount,
        selectedSampleId,
    };
};

const mapDispatchToProps = {
    fetchDataSamplesView: Thunks.fetchDataSamplesView,
    fetchDataSampleCreationStatistics: Thunks.fetchDataSampleCreationStatistics,
    deselectDataSamplesByDocTypeFk: SliceActions.deselectDataSamplesByDocTypeFk,
    selectDataSamples: Thunks.selectDataSamples,
    setDataSamples: SliceActions.setDataSamples,
    setSampleFilter: SliceActions.setSampleFilter,
    setSelectedDataSamples: SliceActions.setSelectedDataSamples
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypesGrid);

const columns = [
    {
        name: 'Selected',
        value: 'Selected',
        align: 'left',
    },
    {
        name: 'Selected Samples (%)',
        value: 'SelectedSamplesCount',
        align: 'left',
    },
    {
        name: 'Document Type',
        value: 'Name',
        filterable: true,
        filter: (x, value) => x.Name.toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
    },
    {
        align: 'left',
        name: 'Validated',
        value: 'Validated',
    },
    {
        align: 'left',
        name: 'Amount',
        value: 'Amount',
    },
];
